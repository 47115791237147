import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InstructionList from '../components/InstructionList';

function PageProfile({ currentUser, showMenu, instructions }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // Make sure we have a user
        if (currentUser === null) {
            return navigate('/');
        }
    }, [currentUser, navigate]);

    function editProfile() {
        return navigate('/edit-profile');
    }

    function goToNextScreen() {
        return navigate('/select-instruction');
    }

    return (<>
        { currentUser ?
            <div className="page-profile">
                <button className="page-title" onClick={showMenu}>{ t('register') }</button>

                <div className="page-profile__left">
                    <div className="profile-welcome">
                        <div className="profile-welcome__top-wrapper profile-welcome__top-wrapper--horizontal">
                            <div className="profile-welcome__img"><img src="/assets/images/profile-avatar.svg" alt="Profile avatar" /></div>
                            <button type="button" onClick={editProfile} className="btn btn--nowrap">{ t('editProfile') }</button>
                        </div>

                        <div>
                            <span className="profile-welcome__name">
                                { t('welcome') }<br/>
                                <strong>{ currentUser.first_name + ' ' + currentUser.last_name }</strong>
                                <img src={ `/assets/images/${ currentUser.language }.png` } alt={ currentUser.language } />
                            </span>

                            <div className="profile-welcome__not-you default-styles">
                                <p>{ t('isThisNotYou') }</p>
                            </div>

                            { currentUser.latest_presence ?
                                <div className="profile-welcome__meta">
                                    <strong>{ t('latestLogin') }</strong><br/>
                                    { t('date') }: { currentUser.latest_presence.date_format }<br/>
                                    { t('goal') }: { currentUser.latest_presence.instruction_title_user_lang }
                                </div>
                            : null }
                        </div>
                    </div>
                </div>

                <div className="page-profile__right page-profile__right--align-top">
                    <InstructionList currentUser={currentUser} instructions={instructions} gridRows={2} size={'small'} showValidity={true} isProfilePage={true} />
                    <button type="button" onClick={goToNextScreen} className="page-profile__continue-btn side-btn">{ t('continue') }</button>
                </div>
            </div>
        : null }
    </>);
}

export default PageProfile;
