import { useNavigate } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ControlButtons from '../components/ControlButtons';

function PageStart() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const videoEl = useRef(null);

    useEffect(() => {
        window.addEventListener('click', goToFullscreen);
        return () => window.removeEventListener('click', goToFullscreen);
    }, []);

    const goToFullscreen = () => {
        document.body.webkitRequestFullscreen();
    };

    const setVideoAspectRatio = () => {
        if (videoEl.current === null) return;

        let newWidth = window.innerWidth;
        let newHeight = newWidth * 9 / 16;

        if (newHeight < window.innerHeight) {
            newHeight = window.innerHeight;
            newWidth = newHeight * 16 / 9;
        }

        videoEl.current.style.width = newWidth + 'px';
        videoEl.current.style.height = newHeight + 'px';
    }

    const goToScan = () => {
        goToFullscreen();
        navigate('/scan');
    };

    const setPreferredLanguage = (lang) => {
        i18n.changeLanguage(lang);
        goToScan();
    };

    setInterval(() => setVideoAspectRatio(), 50);
    window.addEventListener('resize', () => setVideoAspectRatio());

    return (
        <div className="page-start">
            <ControlButtons />

            <div className="page-start__bg-video">
                <video src="/assets/videos/screensaver.mp4" type="video/mp4" ref={videoEl} playsInline autoPlay muted={true} loop={true}></video>
            </div>

            <ul className="page-start__languages">
                <li>
                    <button onClick={ () => setPreferredLanguage('nl') } type="button" className="jsSelectLangBtn" data-lang="nl">
                        <div className="img"><img src="/assets/images/nl.png" alt="Nederlands" /></div>
                        <span>Nederlands</span>
                    </button>
                </li>

                <li>
                    <button onClick={ () => setPreferredLanguage('de') } type="button" className="jsSelectLangBtn" data-lang="de">
                        <div className="img"><img src="/assets/images/de.png" alt="Deutsch" /></div>
                        <span>Deutsch</span>
                    </button>
                </li>

                <li>
                    <button onClick={ () => setPreferredLanguage('en') } type="button" className="jsSelectLangBtn" data-lang="en">
                        <div className="img"><img src="/assets/images/en.png" alt="English" /></div>
                        <span>English</span>
                    </button>
                </li>
            </ul>

            <button onClick={ () => setPreferredLanguage('nl') } className="page-start__start-btn jsStartBtn" type="button">{ t('start') }</button>
        </div>
    );
}

export default PageStart;
