import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Page404({ logout }) {
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate('/');
    }, [logout, navigate]);
    
    return (<></>);
}

export default Page404;
