import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

function PageConfirm({ settings, currentUser, instructions }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [userIsCertified, setUserIsCertified] = useState(null);
    const [currentInstruction, setCurrentInstruction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [licensePlateInput, setLicensePlateInput] = useState('');
    const [confirmVcab, setConfirmVcab] = useState(false);
    const [confirmAdr, setConfirmAdr] = useState(false);
    const [confirmEntry, setConfirmEntry] = useState(false);
    const [visitorCountInput, setVisitorCountInput] = useState('');

    const checkedIconStyle = { backgroundImage: `url('/assets/images/icon-success-white.svg')` };

    const keyboard = useRef();
    const [activeInputEl, setActiveInputEl] = useState(null);
    const [activeInputName, setActiveInputName] = useState(null);
    const keyboardLayout = {
        default: [
            "1 2 3 4 5 6 7 8 9 0 - {bksp}",
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "z x c v b n m",
            "{space}"
        ],
        shift: [
            "1 2 3 4 5 6 7 8 9 0 - {bksp}",
            "Q W E R T Y U I O P",
            "A S D F G H J K L",
            "Z X C V B N M",
            "{space}"
        ],
        numeric: ["1 2 3", "4 5 6", "7 8 9", "0", "{bksp}"]
    };
    const [keyboardLayoutName, setKeyboardLayoutName] = useState('default');

    useEffect(() => {
        // Make sure we have a user
        if (currentUser === null) {
            return navigate('/');
        }

        setLicensePlateInput(currentUser.licence_plate);

        // Check if the user is certified
        let userIsCertified = false;
        currentUser.certifications.forEach(certification => {
            if (certification.instruction_id === parseInt(id) && certification.is_valid) {
                userIsCertified = true;
                setUserIsCertified(userIsCertified);
            }
        });

        if (!userIsCertified) {
            setTimeout(() => {
                navigate('/select-instruction');
            }, 15000);
        }

        instructions.map(instruction => {
            if (instruction.id === parseInt(id)) {
                setCurrentInstruction(instruction);
            }

            return instruction;
        });
    }, [currentUser, id, instructions, navigate]);

    const goToNextScreen = () => {
        if (currentInstruction.required_additional_fields.includes('licence_plate')) {
            if (licensePlateInput === '') {
                alert(t('licensePlateInvalid'));
                return;
            }
        }

        if (currentInstruction.required_additional_fields.includes('has_vca-b_or_vca-vol')) {
            if (!confirmVcab) {
                alert(t('firstConfirmVcab'));
                return;
            }
        }

        if (currentInstruction.required_additional_fields.includes('has_adr')) {
            if (!confirmAdr) {
                alert(t('firstConfirmAdr'));
                return;
            }
        }

        if (currentInstruction.required_additional_fields.includes('visitor_count')) {
            if (visitorCountInput === '' || parseInt(visitorCountInput) < 0) {
                alert(t('visitorCountInvalid'));
                return;
            }
        }

        if (!confirmEntry) {
            alert(t('firstConfirmEntry'));
            return;
        }


        // Register the user presence
        setIsLoading(true);
        const formData = new FormData();

        formData.append('code', currentUser.code);
        formData.append('instruction_id', id);
        if (currentInstruction.required_additional_fields.includes('licence_plate')) formData.append('licence_plate', licensePlateInput);
        if (currentInstruction.required_additional_fields.includes('has_vca-b_or_vca-vol')) formData.append('has_vca-b_or_vca-vol', confirmVcab ? 1 : 0);
        if (currentInstruction.required_additional_fields.includes('has_adr')) formData.append('has_adr', confirmAdr ? 1 : 0);
        if (currentInstruction.required_additional_fields.includes('visitor_count')) formData.append('visitor_count', visitorCountInput);

        fetch(settings.apiBaseUrl + 'register-visitor-presence', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(() => {
                navigate('/success/' + id);
                setIsLoading(false);
            })
        ;
    };

    function onKeyboardChange(input) {
        switch(activeInputName) {
            case 'licencePlate' : setLicensePlateInput(input); break;
            case 'visitorCountInput' : setVisitorCountInput(input); break;
            default : break;
        }
    };

    function onKeyboardKeyPress(button) {
        if (button === '{shift}' || button === '{lock}') {
            setKeyboardLayoutName(keyboardLayoutName === 'default' ? 'shift' : 'default');
        }
    }

    function focusInputEl(inputEl) {
        setActiveInputEl(inputEl);
        keyboard.current.setInput(inputEl.value);

        switch (inputEl.name) {
            case 'licence-plate' : setKeyboardLayoutName('shift'); break;
            case 'visitor-count' : setKeyboardLayoutName('numeric'); break;
            default : break;
        }
    }

    return (<>
        <div className="page-confirm">
            {userIsCertified === true ? <>
                {currentInstruction ? <>
                    <div>
                        <div className="page-confirm__title-wrapper">
                            <span className="page-confirm__title">{t('confirmTitle')}</span>
                            <div className="page-confirm__desc default-styles">{t('confirmDesc')}</div>
                        </div>

                        <div className="page-confirm__form form">
                            <div className="form__row">
                                <label className="form__label">{t('name')}</label>
                                <input type="text" className="form__input" readOnly value={currentUser.first_name + ' ' + currentUser.last_name} disabled />
                            </div>

                            <div className="form__row">
                                <label className="form__label">{t('company')}</label>
                                <input type="text" className="form__input" readOnly value={currentUser.company} disabled />
                            </div>

                            <div className="form__row">
                                <label className="form__label">{t('visitGoal')}</label>
                                <input type="text" className="form__input" readOnly value={currentInstruction['lang_' + currentUser.language].title} disabled />
                            </div>

                            {currentInstruction.required_additional_fields.includes('licence_plate') ? <>
                                <div className="form__row">
                                    <label className="form__label">{t('licencePlate') + ' *'}</label>
                                    <input type="text" name="licence-plate" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('licencePlate') }} className={'form__input' + (activeInputName === 'licencePlate' ? ' focus' : '')} value={licensePlateInput} onChange={(e) => setLicensePlateInput(e.target.value)} />
                                </div>
                            </> : null}

                            {currentInstruction.required_additional_fields.includes('has_vca-b_or_vca-vol') ? <>
                                <div className="form__row">
                                    <div className="form__checkbox-wrapper">
                                        <div>
                                            <input type="checkbox" id="hasVcabVcaVol" checked={confirmVcab} onChange={(e) => { setConfirmVcab(e.target.checked) }} />
                                            <label className="form__checkbox-label" htmlFor="hasVcabVcaVol"><span>{t('hasVcabVcaVol')}</span><div><div style={checkedIconStyle}></div></div></label>
                                        </div>
                                    </div>
                                </div>
                            </> : null}

                            {currentInstruction.required_additional_fields.includes('has_adr') ? <>
                                <div className="form__row">
                                    <div className="form__checkbox-wrapper">
                                        <div>
                                            <input type="checkbox" id="hasAdr" checked={confirmAdr} onChange={(e) => setConfirmAdr(e.target.checked)} />
                                            <label className="form__checkbox-label" htmlFor="hasAdr"><span>{t('hasAdr')}</span><div><div style={checkedIconStyle}></div></div></label>
                                        </div>
                                    </div>
                                </div>
                            </> : null}

                            {currentInstruction.required_additional_fields.includes('visitor_count') ? <>
                                <div className="form__row form__row--wide-label">
                                    <label className="form__label"><span>{t('visitorFactoryCount')}</span></label>
                                    <input type="number" name="visitor-count" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('visitorCountInput') }} min="0" step="1" className={'form__input' + (activeInputName === 'visitorCountInput' ? ' focus' : '')} value={visitorCountInput} onChange={(e) => setVisitorCountInput(e.target.value)} />
                                </div>
                            </> : null}

                            {currentInstruction.required_additional_fields.includes('required_gas_detection_meter') ? <>
                                <div className="form__notice default-styles">
                                    <p>{t('requiredGasDetectionMeter')}</p>
                                </div>
                            </> : null}

                            <div className="form__row">
                                <div className="form__checkbox-wrapper">
                                    <div>
                                        <input type="checkbox" checked={confirmEntry} onChange={(e) => { setConfirmEntry(e.target.checked); }} id="confirmCheckbox" />
                                        <label className="form__checkbox-label form__checkbox-label--confirm" htmlFor="confirmCheckbox"><span>{t('informationIsCorrect')}</span><div><div style={checkedIconStyle}></div></div></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button" onClick={goToNextScreen} className="page-confirm__continue-btn side-btn">{t('continue')}</button>
                </> : null}
            </> : <>
                <div>
                    <div className="page-confirm__title-wrapper">
                        <span className="page-confirm__title">{t('successFailedTitle')}</span>
                        <div className="page-confirm__desc" dangerouslySetInnerHTML={{ __html: t('successFailedDesc') }}></div>
                    </div>
                </div>
            </>}
        </div>

        <div className={'keyboard-wrapper' + (activeInputEl ? ' show' : '') + (keyboardLayoutName === 'numeric' ? ' keyboard-wrapper--numeric' : '')}>
            <Keyboard keyboardRef={r => { keyboard.current = r }} onChange={onKeyboardChange} onKeyPress={onKeyboardKeyPress} layout={keyboardLayout} layoutName={keyboardLayoutName} />
        </div>

        {isLoading ?
            <div className="page-instruction">
                <div className="page-instruction__loading-icon">
                    <img src="/assets/images/icon-loading.png" alt="Loading" />
                </div>
            </div>
            : null}
    </>);
}

export default PageConfirm;