import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function InstructionList({ currentUser, instructions, gridRows, size, showValidity, isProfilePage }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const completedIconStyle = { backgroundImage: `url('/assets/images/icon-success-white.svg')` };
    const expiredIconStyle = { backgroundImage: `url('/assets/images/icon-error-white.svg')` };

    function startInstruction(id) {
        return navigate('/instruction/' + id);
    }

    return (<>
        { currentUser ?
            <div className={ `instruction-list instruction-list--${gridRows}col instruction-list--size-${size}` }>
                {instructions.map((instruction, index) => {
                    return <button className="visitor-type" key={index} onClick={() => { instruction.user_status.completed && instruction.user_status.is_valid && !isProfilePage ? navigate('/confirm/' + instruction.id) : startInstruction(instruction.id) }}>
                        <div className="visitor-type__image-wrapper">
                            <img src={instruction.thumbnail_url} alt={instruction['lang_' + currentUser.language].title} />
                        </div>

                        <span className="visitor-type__name">{instruction['lang_' + currentUser.language].title}</span>

                        {instruction.user_status.completed && instruction.user_status.is_valid ? <div className="visitor-type__certified-badge"><span style={ completedIconStyle }></span>{ t('instructionCompletedValidStatus') }</div> : null }
                        {isProfilePage && instruction.user_status.completed && !instruction.user_status.is_valid ? <div className="visitor-type__expired-badge"><span style={ expiredIconStyle }></span>{ t('instructionCompletedExpiredStatus') }</div> : null }
                        {isProfilePage && !instruction.user_status.completed ? <div className="visitor-type__certified-status">{ t('instructionNotCompleted') }</div> : null }

                        {showValidity && instruction.user_status.completed && instruction.user_status.is_valid ?
                            <span className="visitor-type__certified-status">{ t('validUntil') + ' ' + instruction.user_status.expiration_date_format }</span>
                        : null }
                    </button>
                })}
            </div>
        : null }
    </>);
}

export default InstructionList;