import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './../i18n';
import { useTranslation } from 'react-i18next';

import PageStart from './PageStart';
import PageScan from './PageScan';
import PageInstructions from './PageInstructions';
import PageWelcome from './PageWelcome';
import PageRegister from './PageRegister';
import PageInstruction from './PageInstruction';
import PageEditProfile from './PageEditProfile';
import Menu from '../components/Menu';
import PageProfile from './PageProfile';
import PageConfirm from './PageConfirm';
import PageSuccess from './PageSuccess';
import InactiveModal from '../components/InactiveModal';
import Page404 from './Page404';

function App() {
    const { i18n } = useTranslation();
    const settings = { apiBaseUrl: process.env.NODE_ENV === 'production' ? 'https://gmbstopwatch.eu/backend/wp-json/gmb/v1/' : 'https://gmb.test/wp-json/gmb/v1/' };

    const [currentUser, setCurrentUser] = useState(null);
    const [currentCode, setCurrentCode] = useState(null);
    const [instructions, setInstructions] = useState([]);
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    useEffect(() => {
        // Disable the browser back button when in production
        if (process.env.NODE_ENV === 'production' ) {
            window.addEventListener('popstate', () => window.history.go(1));
        }
    }, []);

    useEffect(() => {
        if (!currentUser) {
            setInstructions([])
            return;
        }

        fetch(settings.apiBaseUrl + 'get-instructions')
            .then(response => response.json())
            .then(data => {
                data = data.map(row => {
                    row.user_status = {
                        completed: false,
                        is_valid: false,
                        expiration_date_format: null
                    };

                    currentUser.certifications?.forEach(certification => {
                        if (certification.instruction_id === row.id) {
                            row.user_status.completed = true;
                            row.user_status.is_valid = certification.is_valid;
                            row.user_status.expiration_date_format = certification.expiration_date_format;
                        }
                    });

                    row.min_score = parseInt(row.min_score);

                    return row;
                });

                setInstructions(data);
            });
    }, [currentUser, settings.apiBaseUrl]);

    function onSetCurrentUser(user) {
        setCurrentUser(user);
        i18n.changeLanguage(user.language);
    }

    function showMenu() {
        setMenuIsVisible(true);
    }

    function updateCurrentUser(callback) {
        if (!currentUser) console.error('Cannot update current user, no user is set');

        fetch(settings.apiBaseUrl + 'get-visitor/' + currentUser.code)
            .then(response => response.json())
            .then(data => {
                if (data.visitor == null) {
                    logout();
                    if (typeof callback !== 'undefined') callback();
                }
                else {
                    setCurrentUser(data.visitor);
                    i18n.changeLanguage(data.visitor.language);
                    if (typeof callback !== 'undefined') callback();
                }
            });
    }

    function logout() {
        setCurrentUser(null);
        setCurrentCode(null);
    }

    return (<>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageStart />} />
                <Route path="/scan" element={<PageScan settings={settings} onSetCurrentUser={onSetCurrentUser} onSetCurrentCode={code => { setCurrentCode(code) }} />} />
                <Route path="/register" element={<PageRegister showMenu={showMenu} settings={settings} currentCode={currentCode} onSetCurrentUser={onSetCurrentUser} />} />
                <Route path="/welcome" element={<PageWelcome showMenu={showMenu} currentUser={currentUser} />} />
                <Route path="/select-instruction" element={<PageInstructions showMenu={showMenu} currentUser={currentUser} instructions={instructions} />} />
                <Route path="/instruction/:id" element={<PageInstruction instructions={instructions} currentUser={currentUser} settings={settings} logout={logout} updateCurrentUser={updateCurrentUser} />} />
                <Route path="/profile" element={<PageProfile showMenu={showMenu} currentUser={currentUser} instructions={instructions} />} />
                <Route path="/edit-profile" element={<PageEditProfile showMenu={showMenu} settings={settings} currentUser={currentUser} onSetCurrentUser={onSetCurrentUser} />} />
                <Route path="/confirm/:id" element={<PageConfirm settings={settings} currentUser={currentUser} instructions={instructions} />} />
                <Route path="/success/:id" element={<PageSuccess currentUser={currentUser} logout={logout} />} />
                <Route path="*" exact={true} element={<Page404 logout={logout} />} />
            </Routes>

            <Menu currentUser={currentUser} isVisible={menuIsVisible} setMenuIsVisible={setMenuIsVisible} logout={logout} />
            <InactiveModal logout={logout} />
        </BrowserRouter>
    </>);
}

export default App;
