import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InstructionList from '../components/InstructionList';

function PageInstructions({ currentUser, instructions, showMenu }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // Make sure we have a user
        if (currentUser === null) {
            return navigate('/');
        }
    }, [currentUser, navigate]);

    return (<>
        {currentUser ?
            <div className="page-instructions">
                <button className="page-title page-title--light" onClick={showMenu}>{t('visitGoal')}</button>

                <div>
                    <span className="page-instructions__title">{currentUser.first_name}, {t('whatBringsYouHere')}</span>
                    <InstructionList currentUser={currentUser} instructions={instructions} gridRows={3} size={'large'} showValidity={false} isProfilePage={false} />
                </div>
            </div>
        : null}
    </>);
}

export default PageInstructions;
