import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function PageWelcome({ currentUser, showMenu }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // Make sure we have a user
        if (currentUser === null) {
            return navigate('/');
        }
    }, [currentUser, navigate]);

    function goToNextScreen() {
        return navigate('/select-instruction');
    }

    return (<>
        {currentUser ?
            <div className="page-profile">
                <button className="page-title" onClick={showMenu}>{t('myProfile')}</button>

                <div className="page-profile__left">
                    <div className="profile-welcome">
                        <div className="profile-welcome__top-wrapper">
                            <div className="profile-welcome__img"><img src="/assets/images/profile-avatar.svg" alt="Profile avatar" /></div>
                        </div>

                        <div>
                            <span className="profile-welcome__name">
                                <strong>{currentUser.first_name + ' ' + currentUser.last_name}</strong>
                                <img src={`/assets/images/${currentUser.language}.png`} alt={currentUser.language} />
                            </span>

                            {currentUser.latest_presence ?
                                <div className="profile-welcome__meta">
                                    <strong>{t('latestLogin')}</strong><br />
                                    {t('date')}: {currentUser.latest_presence.date_format}
                                </div>
                            : null}
                        </div>
                    </div>
                </div>

                <div className="page-profile__right page-profile__right--center">
                    <div className="page-profile__instruction-wrapper">
                        <div>
                            <span className="title" dangerouslySetInnerHTML={{ __html: t('welcomeIntroTitle') }}></span>
                            <div className="description default-styles" dangerouslySetInnerHTML={{ __html: t('welcomeIntroDesc') }}></div>
                        </div>
                    </div>

                    <button type="button" onClick={goToNextScreen} className="page-profile__continue-btn side-btn">{t('continue')}</button>
                </div>
            </div>
            : null}
    </>);
}

export default PageWelcome;
