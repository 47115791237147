import { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PageInstruction({ instructions, currentUser, settings, logout, updateCurrentUser }) {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const videoEl = useRef(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [currentInstruction, setCurrentInstruction] = useState(null);
    const [showStopScreenEl, setShowStopScreenEl] = useState(false);
    const [showQuestionOverlayEl, setShowQuestionOverlayEl] = useState(false);
    const [questionScores, setQuestionScores] = useState([]);
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [preventTimeSkip, setPreventTimeSkip] = useState(null);
    const [nextTimeframe, setNextTimeframe] = useState(null);
    const [showContinueBtn, setShowContinueBtn] = useState(false);
    const [showFinalScreen, setShowFinalScreen] = useState(false);
    const [instructionWasSuccess, setInstructionWasSuccess] = useState(false);
    const [insideNoAnswerCatch, setInsideNoAnswerCatch] = useState(false);

    const [timeframeUpdateInterval, setTimeframeUpdateInterval] = useState(null);

    // Initialization
    useEffect(() => {
        // Make sure we have a user
        if (currentUser === null) {
            return navigate('/');
        }

        // Find the current instruction
        instructions.map(instruction => {
            if (instruction.id === parseInt(id)) {
                setCurrentInstruction(instruction);

                // Create the score array
                const newScoreArray = [];
                instruction.questions?.map(() => newScoreArray.push(0));
                setQuestionScores(newScoreArray);
            }

            return instruction;
        });
    }, [currentUser, id, instructions, navigate]);

    // Listen to the inactivity modal events
    useEffect(() => {
        const f1 = () => videoEl.current.pause();
        window.addEventListener('gmb-show-inactive-modal', f1);

        const f2 = () => videoEl.current.play();
        window.addEventListener('gmb-hide-inactive-modal', f2);

        return () => {
            window.removeEventListener('gmb-show-inactive-modal', f1);
            window.removeEventListener('gmb-hide-inactive-modal', f2);
        };
    });

    const handleTimeframeUpdate = useRef(null);
    handleTimeframeUpdate.current = (time) => {
        if (!currentInstruction) return;
        let hasActiveQuestion = false;

        currentInstruction.questions?.map((question, index) => {
            if (time >= question.timeframe_start && time <= question.timeframe_end) {
                hasActiveQuestion = true;
            }

            if (preventTimeSkip !== null) { // We are inside the answer options of a question
                if (currentInstruction.show_dev_overlay) {
                    console.log('Prevent skipping', time, preventTimeSkip, nextTimeframe);
                }

                if (time >= preventTimeSkip) {
                    videoEl.current.currentTime = nextTimeframe;

                    if (insideNoAnswerCatch) {
                        setInsideNoAnswerCatch(false);
                        giveAnswer(-1);
                    }
                    else {
                        setPreventTimeSkip(null);
                        setNextTimeframe(null);
                    }
                }
            }
            else if (time >= question.timeframe_start && time <= question.timeframe_end) {
                setCurrentQuestionIndex(index);
                setShowQuestionOverlayEl(true);

                // Set the 'wrong answer' timeframes for when the user does not give an answer
                setPreventTimeSkip(question.timeframe_end);
                setNextTimeframe(question.no_answer.timeframe_start);
                setInsideNoAnswerCatch(true);
            }

            return question;
        });

        if (!hasActiveQuestion) {
            setCurrentQuestionIndex(-1);
            setShowQuestionOverlayEl(false);
        }

        // Check if the video has ended
        if (time >= videoEl.current.duration - 0.5) {
            clearInterval(timeframeUpdateInterval);
            setTimeframeUpdateInterval(null);

            videoEl.current.pause();
            videoEnded();
        }
    }

    const continueClicked = () => {
        setShowContinueBtn(false);

        if (!nextTimeframe) return;

        videoEl.current.currentTime = nextTimeframe;

        setPreventTimeSkip(null);
        setNextTimeframe(null);

        videoEl.current.play();
    }

    const showStopScreen = () => {
        setShowStopScreenEl(true);
        videoEl.current.pause();
    }

    const hideStopScreen = () => {
        setShowStopScreenEl(false);
        videoEl.current.play();
    }

    const giveAnswer = (answerIndex) => {
        if (currentQuestionIndex < 0) return;

        const scoresClone = [...questionScores];
        const answer = answerIndex > -1 ? currentInstruction.questions[currentQuestionIndex]['answer_' + (answerIndex + 1)] : { points: 0, timeframe_start: currentInstruction.questions[currentQuestionIndex].no_answer.timeframe_start, timeframe_end: currentInstruction.questions[currentQuestionIndex].no_answer.timeframe_end };

        setShowQuestionOverlayEl(false);

        // Save the score
        scoresClone[currentQuestionIndex] = answer.points;
        setQuestionScores(scoresClone);

        videoEl.current.currentTime = answer.timeframe_start;

        // Prevent the video from going past the answer timeframe. We use this to set the timeframe for the continue button
        setInsideNoAnswerCatch(false);
        setPreventTimeSkip(answer.timeframe_end);
        setNextTimeframe(currentInstruction.questions[currentQuestionIndex].timeframe_question_end);
    }

    const getTotalScore = useCallback(() => {
        return questionScores.reduce((a, b) => a + b, 0);
    }, [questionScores]);

    const continueFromFinalScreen = useCallback(() => {
        if (instructionWasSuccess) navigate('/confirm/' + id);
        else navigate('/select-instruction');
    }, [instructionWasSuccess, navigate, id]);

    const videoEnded = useCallback(() => {
        if (isVideoEnded) return;
        setIsVideoEnded(true);

        const totalScore = getTotalScore();

        if (totalScore >= currentInstruction.min_score) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('code', currentUser.code);
            formData.append('instruction_id', currentInstruction.id);
            formData.append('preferred_language', i18n.language);

            fetch(settings.apiBaseUrl + 'certify-visitor', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.success) {
                        console.error(data.err);
                        alert(data.err_msg);

                        navigate('/select-instruction');
                        return;
                    }

                    updateCurrentUser(() => {
                        setIsLoading(false);
                        setInstructionWasSuccess(true);
                        setShowFinalScreen(true);
                    });
                });
        }
        else {
            setInstructionWasSuccess(false);
            setShowFinalScreen(true);
        }
    }, [currentInstruction, currentUser, getTotalScore, i18n.language, isVideoEnded, navigate, settings, updateCurrentUser]);

    // Monitor the video progress
    useEffect(() => {
        if (currentInstruction !== null && !currentInstruction.use_video) {
            videoEnded();
            return;
        }

        const interval = setInterval(() => {
            if (!videoEl.current) return;
            handleTimeframeUpdate.current(videoEl.current.currentTime);
        }, 200);

        setTimeframeUpdateInterval(interval);

        return () => {
            clearInterval(interval);
            setTimeframeUpdateInterval(null);
        };
    }, [currentInstruction, videoEnded]);

    return (<>
        {currentInstruction ?
            <div className={'page-instruction' + (currentInstruction.show_dev_overlay ? ' page-instruction--dev' : '')}>
                <div className="page-instruction__video-wrapper">
                    <video className="page-instruction__video" ref={videoEl} src={currentInstruction['lang_' + i18n.language].video_url} autoPlay playsInline type="video/mp4"></video>

                    <div className={'page-instruction__question-overlay' + (showQuestionOverlayEl === true ? ' show' : '') + (currentQuestionIndex > -1 ? (' count-' + currentInstruction.questions[currentQuestionIndex].answer_count) : '')}>
                        <button onClick={() => giveAnswer(0)} className="button button-1" type="button"></button>
                        <button onClick={() => giveAnswer(1)} className="button button-2" type="button"></button>
                        <button onClick={() => giveAnswer(2)} className="button button-3" type="button"></button>
                    </div>
                </div>

                <button onClick={showStopScreen} type="button" className="side-btn side-btn--left side-btn--solid">{t('stopInstruction')}</button>

                <div className={'page-instruction__stop-screen stop-screen' + (showStopScreenEl ? ' show' : '')}>
                    <div className="stop-screen__inner">
                        <span className="stop-screen__title">{t('stopScreenTitle')}</span>

                        <div className="stop-screen__btns">
                            <button type="button" onClick={hideStopScreen} className="btn btn--low btn--solid-yellow">{t('stopInstructionNo')}</button>
                            <button type="button" onClick={() => navigate('/profile')} className="btn btn--low btn--solid-yellow">{t('stopInstructionOther')}</button>
                            <button type="button" onClick={() => { logout(); navigate('/'); }} className="btn btn--low btn--solid-yellow">{t('stopInstructionLogout')}</button>
                        </div>
                    </div>
                </div>

                {showContinueBtn ? <button onClick={continueClicked} className="page-instruction__continue-btn side-btn">{t('instructionContinue')}</button> : null}

                {showFinalScreen &&
                    <div className="page-instruction__final-screen final-screen">
                        <div>
                            <span className="final-screen__title">{t(instructionWasSuccess ? (currentInstruction.use_video ? 'instructionWasSuccessTitle' : 'instructionNoVideoSuccessTitle') : 'instructionFailedTitle')}</span>
                            <div className="final-screen__desc default-styles" dangerouslySetInnerHTML={{ __html: t(instructionWasSuccess ? 'instructionWasSuccessDesc' : 'instructionFailedDesc') }}></div>

                            <div className="final-screen__btns">
                                <button type="button" className="btn btn--solid-yellow" onClick={continueFromFinalScreen}>{t('inactiveModalStillHereBtn')}</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            : null}

        {!currentInstruction || isLoading ?
            <div className="page-instruction">
                <div className="page-instruction__loading-icon">
                    <img src="/assets/images/icon-loading.png" alt="Loading" />
                </div>
            </div>
            : null}
    </>);
}

export default PageInstruction;
