import React from 'react';

function ControlButtons() {
    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    return (
        <div className="control-btns">
            <button className="escape" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/icon-fullscreen.svg)` }} onClick={toggleFullScreen}></button>
            <button className="reload" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/icon-reload.svg)` }} onClick={() => window.location.reload()}>Herladen</button>
        </div>
    );
}

export default ControlButtons;