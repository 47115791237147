import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function PageSuccess({ currentUser, logout }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [isUserCertified, setIsUserCertified] = useState(null);

    const preventUnauthorizedVisit = useCallback(() => {
        if (currentUser === null) {
            console.error('No current user, logout!');
            return navigate('/');
        }
    }, [currentUser, navigate]);

    const checkIfUserIsCertified = useCallback(() => {
        let isCertified = false;

        currentUser.certifications.forEach(certification => {
            if (certification.instruction_id === parseInt(id) && certification.is_valid) {
                setIsUserCertified(true);
                isCertified = true;
            }
        });

        return isCertified;
    }, [currentUser, id]);

    useEffect(() => {
        // Make sure we have a user
        preventUnauthorizedVisit();

        // Check if the user is certified and logout
        const result = checkIfUserIsCertified();
        const timeout = setTimeout(() => {
            if (result) {
                logout();
                navigate('/');
            }
            else {
                navigate('/select-instruction');
            }
        }, 15000);

        return () => clearTimeout(timeout);
    }, [checkIfUserIsCertified, preventUnauthorizedVisit, navigate, logout]);

    return (<>
        <div className="page-success">
            <div>
                <span className="page-success__title">{ t(isUserCertified ? 'successSuccessTitle' : 'successFailedTitle') }</span>
                <div className="page-success__desc default-styles" dangerouslySetInnerHTML={ { __html: t(isUserCertified ? 'successSuccessDesc' : 'successFailedDesc') } }></div>
            </div>
        </div>
    </>);
}

export default PageSuccess;