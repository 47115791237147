import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            nl: {
                translation: {
                    start: 'Start',
                    continue: 'Ga verder',
                    welcome: 'Welkom',
                    register: 'Aanmelden',
                    menu: 'Menu',
                    myProfile: 'Mijn profiel',
                    securityInstructions: 'Veiligheidsinstructies',
                    stop: 'Stoppen',
                    selectLanguage: 'Taal kiezen',
                    latestLogin: 'Laatste login',
                    visitGoal: 'Doel bezoek',
                    inactiveModalTitle: 'Ben je er nog?',
                    inactiveModalDesc: '<p>Het lijkt erop alsof je al enige tijd niet meer actief bent. Geef aan of je verder wilt gaan of opnieuw wilt beginnen.</p><p>Je wordt automatisch uitgelogd over 5 minuten.</p>',
                    inactiveModalStillHereBtn: 'Ga verder',
                    inactiveModalStopBtn: 'Stop',
                    name: 'Naam',
                    firstName: 'Voornaam',
                    lastName: 'Achternaam',
                    email: 'E-mailadres',
                    company: 'Bedrijf',
                    licencePlate: 'Kenteken',
                    preferredLanguage: 'Voorkeurstaal',
                    informationIsCorrect: 'Bovenstaande informatie is juist.',
                    firstConfirmEntry: 'Geef aan of de informatie juist is ingevuld.',
                    visitorCountInvalid: 'Geef aan hoeveel bezoekers er mee de fabriek in gaan.',
                    licensePlateInvalid: 'Vul de kentekenplaat van uw vrachtwagen in.',
                    firstConfirmVcab: 'VCA-B of VCA-VOL verplicht',
                    firstConfirmAdr: 'ADR verplicht',
                    myCode: 'Mijn code',
                    signIn: 'Aanmelden',
                    startScan: 'Scan jouw QR-code om je aan te melden',
                    scanPassInstruction: 'Houd je pasje voor de scanner en wacht op het groene vinkje',
                    scanPassErrorMsg: 'Ongeldige code gevonden. Scan je QR-code nog een keer a.u.b.',
                    scanForgotPass: 'Ben je je pas vergeten? Meld je dan bij de balie.',
                    scanHavingProblemsInstruction: 'Ik wil mijn code handmatig invoeren.',
                    instructions: 'Instructies',
                    stopInstruction: 'Stop instructie',
                    whatBringsYouHere: 'wat brengt jou hier?',
                    instructionCompletedValidStatus: 'Gecertificeerd',
                    instructionCompletedExpiredStatus: 'Verlopen',
                    instructionNotCompleted: 'Nog niet bekeken',
                    validUntil: 'Geldig tot',
                    watchAgain: 'Bekijk opnieuw',
                    watch: 'Start instructie',
                    stopScreenTitle: 'Weet je zeker dat je wilt stoppen met de veiligheidsinstructie?',
                    stopInstructionNo: 'Nee, ik wil niet stoppen',
                    stopInstructionOther: 'Ik wil een andere instructie',
                    stopInstructionLogout: 'Ik wil me opnieuw aanmelden',
                    instructionContinue: 'Ga verder',
                    instructionWasSuccessTitle: 'Gefeliciteerd je bent gecertificeerd!',
                    instructionFailedTitle: 'Helaas, je kent de veiligheidsvoorschriften niet voldoende',
                    instructionWasSuccessDesc: 'Klik hieronder op \'Ga verder\' om wat laatste vragen in te vullen.',
                    instructionFailedDesc: 'Bekijk de instructiefilm nogmaals en maak de vragen met aandacht. Succes!',
                    profile: 'Profiel',
                    saveProfile: 'Opslaan',
                    welcomeIntroTitle: 'Welkom bij<br/>GMB BioEnergie',
                    welcomeIntroDesc: '<p>Ben jij de persoon hier links? Klik dan op \'Ga verder\'. Meld je anders bij de balie.</p>',
                    date: 'Datum',
                    goal: 'Doel',
                    editProfile: 'Profiel bewerken',
                    registerIntro: 'Maak nu een profiel aan en bekijk jouw beveiligingscertificaten.',
                    isThisNotYou: 'Ben je dit niet? Meld je dan bij de balie.',
                    confirmTitle: 'We zijn er bijna!',
                    confirmDesc: 'Controleer je gegevens en vink deze af.',
                    successSuccessTitle: 'Succes met uw bezoek en graag tot een volgende keer!',
                    successSuccessDesc: '<p>U mag het terrein op. Dit scherm gaat automatisch terug naar het beginscherm.</p>',
                    successFailedTitle: 'Niet gelukt',
                    successFailedDesc: '<p>Helaas ben je nog niet gecertificeerd voor deze instructie, of is je certificering verlopen. Je wordt automatisch doorgestuurd.</p>',
                    hasVcabVcaVol: 'Ik ben in het bezit van geldige VCA-B of VCA-VOL',
                    hasAdr: 'Ik ben in het bezit van een geldig ADR-certificaat',
                    visitorFactoryCount: 'Hoeveel bezoekers gaan er mee de fabriek in?',
                    requiredGasDetectionMeter: 'Vergeet niet een gasdetectiemeter mee te nemen als je de fabriek in moet!',
                    givenCodeIsInvalid: 'De ingevoerde code is ongeldig. Controleer de invoer en probeer het opnieuw.'
                }
            },
            de: {
                translation: {
                    start: 'Start',
                    continue: 'Weiter',
                    welcome: 'Willkommen',
                    register: 'Anmelden',
                    menu: 'Menü',
                    myProfile: 'Mein Profil',
                    securityInstructions: 'Sicherheitshinweise',
                    stop: 'Stop',
                    selectLanguage: 'Sprache wählen',
                    latestLogin: 'Letzter Login',
                    visitGoal: 'Grund des Besuchs',
                    inactiveModalTitle: 'Sind Sie noch da?',
                    inactiveModalDesc: '<p>Es sieht so aus, als wären Sie einige Zeit inaktiv gewesen. Bitte geben Sie an, ob Sie fortfahren oder neu beginnen möchten. </p> <p> Sie werden in 5 Minuten automatisch abgemeldet.</p>',
                    inactiveModalStillHereBtn: 'Weiter',
                    inactiveModalStopBtn: 'Stop',
                    name: 'Name',
                    firstName: 'Vorname',
                    lastName: 'Nachname',
                    email: 'E-Mail',
                    company: 'Firma',
                    licencePlate: 'Kfz-Kennzeichen',
                    preferredLanguage: 'Bevorzugte Sprache',
                    informationIsCorrect: 'Die oben genannten Informationen sind korrekt.',
                    firstConfirmEntry: 'Bitte geben Sie an, ob die Informationen korrekt eingegeben wurden.',
                    visitorCountInvalid: 'Geben Sie an, wie viele Besucher die Fabrik besuchen werden.',
                    licensePlateInvalid: 'Geben Sie das Kfz-Kennzeichen Ihres Lkw ein.',
                    firstConfirmVcab: 'VCA-B oder VCA-VOL obligatorisch',
                    firstConfirmAdr: 'ADR obligatorisch',
                    myCode: 'Mein Code',
                    signIn: 'Anmelden',
                    startScan: 'Scannen Sie Ihren QR-Code, um sich anzumelden',
                    scanPassInstruction: 'Halten Sie Ihren Ausweis vor den Scanner und warten Sie, bis das grüne Häkchen erscheint.',
                    scanPassErrorMsg: 'Ungültiger Code, bitte scannen Sie Ihren QR-Code erneut.',
                    scanForgotPass: 'Sie haben Ihren Ausweis vergessen? Dann melden Sie sich an der Rezeption.',
                    scanHavingProblemsInstruction: 'Ich möchte meinen Code manuell eingeben.',
                    instructions: 'Anweisungen',
                    stopInstruction: 'Anweisung stoppen',
                    whatBringsYouHere: 'Was führt Sie hierher?',
                    instructionCompletedValidStatus: 'Zertifiziert',
                    instructionCompletedExpiredStatus: 'Abgelaufen',
                    instructionNotCompleted: 'Noch nicht gesehen',
                    validUntil: 'Gültig bis',
                    watchAgain: 'Nochmals ansehen',
                    watch: 'Anweisung starten',
                    stopScreenTitle: 'Sind Sie sicher, dass Sie die Sicherheitsunterweisung abbrechen wollen?',
                    stopInstructionNo: 'Nein, ich möchte nicht aufhören',
                    stopInstructionOther: 'Ich möchte eine andere Anweisung',
                    stopInstructionLogout: 'Ich möchte mich erneut anmelden',
                    instructionContinue: 'Weiter',
                    instructionWasSuccessTitle: 'Gratulation Sie sind zertifiziert!',
                    instructionFailedTitle: 'Leider kennen Sie die Sicherheitsvorschriften nicht gut genug',
                    instructionWasSuccessDesc: 'Klicken Sie unten auf "Weiter", um einige letzte Fragen zu beantworten.',
                    instructionFailedDesc: 'Schauen Sie sich das Anleitungsvideo noch einmal an und bearbeiten Sie die Fragen mit Sorgfalt. Viel Glück!',
                    profile: 'Profil',
                    saveProfile: 'Speicher/Sichern',
                    welcomeIntroTitle: 'Willkommen bei<br/>GMB BioEnergie',
                    welcomeIntroDesc: '<p>Bist du die Person hier links? Klicke dann auf \'Weiter\'. Andernfalls melde dich bitte am Empfang.',
                    date: 'Datum',
                    goal: 'Ziel',
                    editProfile: 'Profil editieren',
                    registerIntro: 'Erstellen Sie jetzt ein Profil und sehen Sie Ihre Sicherheitszertifikate ein.',
                    isThisNotYou: 'Sind Sie nicht diese Person? Bitte melden Sie sich an der Rezeption.',
                    confirmTitle: 'Wir sind fast da!',
                    confirmDesc: 'Überprüfen Sie Ihre Angaben und haken Sie sie ab.',
                    successSuccessTitle: 'Viel Erfolg bei Ihrem Besuch und bis zum nächsten Mal!',
                    successSuccessDesc: '<p>Sie können die Räumlichkeiten betreten. Dieser Bildschirm kehrt automatisch zum Startbildschirm zurück.</p>',
                    successFailedTitle: 'Gescheitert',
                    successFailedDesc: '<p>Da Sie für diese Anweisung noch nicht zertifiziert sind, oder Ihre Zertifizierung abgelaufen ist. Sie werden automatisch weitergeleitet.</p>',
                    hasVcabVcaVol: 'Ich bin im Besitz eines gültigen VCA-B oder VCA-VOL',
                    hasAdr: 'Ich besitze eine gültige ADR-Bescheinigung.',
                    visitorFactoryCount: 'Wie viele Besucher werden die Fabrik betreten?',
                    requiredGasDetectionMeter: 'Vergiss nicht, einen Gasmessgerät mitzunehmen, wenn du in die Fabrik musst!',
                    givenCodeIsInvalid: 'Der eingegebene Code ist ungültig. Überprüfen Sie die Eingabe und versuchen Sie es erneut.'
                }
            },
            en: {
                translation: {
                    start: 'Start',
                    continue: 'Next',
                    welcome: 'Welcome',
                    register: 'Register',
                    menu: 'Menu',
                    myProfile: 'My profile',
                    securityInstructions: 'Safety instructions',
                    stop: 'Stop',
                    selectLanguage: 'Select language',
                    latestLogin: 'Latest login',
                    visitGoal: 'Purpose of visit',
                    inactiveModalTitle: 'Are you still here?',
                    inactiveModalDesc: '<p>It seems like you have been inactive for a while. Please indicate whether you want to continue or start over. </p> <p> You will be automatically logged out in 5 minutes.</p>',
                    inactiveModalStillHereBtn: 'Continue',
                    inactiveModalStopBtn: 'Stop',
                    name: 'Name',
                    firstName: 'First name',
                    lastName: 'Last name',
                    email: 'Email',
                    company: 'Firm',
                    licencePlate: 'Licence plate',
                    preferredLanguage: 'Preferred language',
                    informationIsCorrect: 'The above information is correct.',
                    firstConfirmEntry: 'Please indicate whether the information is entered correctly.',
                    visitorCountInvalid: 'Indicate how many visitors will visit the factory.',
                    licensePlateInvalid: 'Enter the registration plate of your truck.',
                    firstConfirmVcab: 'VCA-B or VCA-VOL required',
                    firstConfirmAdr: 'ADR required',
                    myCode: 'My code',
                    signIn: 'Sign in',
                    startScan: 'Scan your QR-code to sign in',
                    scanPassInstruction: 'Hold your card in front of the scanner and wait for the green check mark',
                    scanPassErrorMsg: 'Invalid code found. Please scan your QR-code again.',
                    scanForgotPass: 'Did you forget your pass? Please report to the front desk.',
                    scanHavingProblemsInstruction: 'I want to enter my code manually.',
                    instructions: 'Instructions',
                    stopInstruction: 'Stop instruction',
                    whatBringsYouHere: 'what brings you here?',
                    instructionCompletedValidStatus: 'Certified',
                    instructionCompletedExpiredStatus: 'Expired',
                    instructionNotCompleted: 'Not yet viewed',
                    validUntil: 'Valid until',
                    watchAgain: 'View again',
                    watch: 'Start instruction',
                    stopScreenTitle: 'Are you sure you want to stop the safety instruction?',
                    stopInstructionNo: 'No, I don\'t want to stop',
                    stopInstructionOther: 'I want another instruction',
                    stopInstructionLogout: 'I want to log in again',
                    instructionContinue: 'Continue',
                    instructionWasSuccessTitle: 'Congratulations you are certified!',
                    instructionFailedTitle: 'Unfortunately, you are not sufficiently familiar with the safety regulations',
                    instructionWasSuccessDesc: 'Click \'Continue\' below to answer some final questions.',
                    instructionFailedDesc: 'Watch the instructional film again and answer the questions carefully. Good luck!',
                    profile: 'Profile',
                    saveProfile: 'Save',
                    welcomeIntroTitle: 'Welcome to<br/>GMB BioEnergy',
                    welcomeIntroDesc: '<p>Are you the person on the left? Click \'Continue\' in that case. Otherwise, please check in at the reception.',
                    date: 'Date',
                    goal: 'Target',
                    editProfile: 'Edit profile',
                    registerIntro: 'Create a profile now and view your security certificates.',
                    isThisNotYou: 'Are you not this person? Please report to the reception desk.',
                    confirmTitle: 'Almost ready!',
                    confirmDesc: 'Make sure your info is correct.',
                    successSuccessTitle: 'Good luck with your visit and see you next time!',
                    successSuccessDesc: '<p>You can enter the property. This screen automatically returns to the home screen.</p>',
                    successFailedTitle: 'Did not succeed',
                    successFailedDesc: '<p>Unfortunately, you are not yet certified for this instruction, or your certification has expired. You will be automatically forwarded.</p>',
                    hasVcabVcaVol: 'I am in possession of a valid VCA-B or VCA-VOL',
                    hasAdr: 'I am in possession of a valid ADR certificate',
                    visitorFactoryCount: 'How many visitors will enter the factory?',
                    requiredGasDetectionMeter: 'Don\'t forget to take a gas detection meter with you if you need to enter the factory!',
                    givenCodeIsInvalid: 'The entered code is invalid. Check the input and try again.'
                }
            }
        }
    });

export default i18n;