import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

function PageEditProfile({ settings, currentUser, onSetCurrentUser, showMenu }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [formIsDisabled, setFormIsDisabled] = useState(false);
    const [currentUserTmp, setCurrentUserTmp] = useState(null);

    const keyboard = useRef();
    const [activeInputEl, setActiveInputEl] = useState(null);
    const [activeInputName, setActiveInputName] = useState(null);
    const [keyboardLayout, setKeyboardLayout] = useState('default');
    const excludeFromKeyboardLayout = { default: ['{tab}', '{enter}', '{lock}'], shift: ['{tab}', '{enter}', '{lock}'] };

    useEffect(() => {
        // Make sure we have a user
        if (currentUser === null) {
            return navigate('/');
        }

        setCurrentUserTmp(currentUser);
    }, [currentUser, navigate]);

    function updateUser(e) {
        const currentUserTmpClone = { ...currentUserTmp };
        currentUserTmpClone[e.target.name] = e.target.value;

        i18n.changeLanguage(currentUserTmpClone.language);
        setCurrentUserTmp(currentUserTmpClone);
    }

    function onKeyboardChange(input) {
        updateUser({
            target: {
                name: activeInputEl.name,
                value: input
            }
        });
    };

    function onKeyboardKeyPress(button) {
        if (button === '{shift}' || button === '{lock}') {
            setKeyboardLayout(keyboardLayout === 'default' ? 'shift' : 'default');
        }
    }

    function focusInputEl(inputEl) {
        setActiveInputEl(inputEl);
        keyboard.current.setInput(inputEl.value);
    }

    function saveProfile(e) {
        e.preventDefault();

        onSetCurrentUser(currentUserTmp);
        setFormIsDisabled(true);

        const formData = new FormData(e.target);

        formData.append('code', currentUser.code);
        formData.append('first_name', currentUserTmp.first_name);
        formData.append('last_name', currentUserTmp.last_name);
        formData.append('email', currentUserTmp.email);
        formData.append('company', currentUserTmp.company);
        formData.append('preferred_language', currentUserTmp.language);
        formData.append('licence_plate', currentUserTmp.licence_plate);

        fetch(settings.apiBaseUrl + 'update-visitor', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    setFormIsDisabled(false);

                    console.error(data.err);
                    alert(data.err_msg);
                    return;
                }

                // Set the current user and redirect
                onSetCurrentUser(data.user);
                return navigate('/profile');
            })
            ;
    }

    return (<>
        {currentUserTmp ?
            <div className="page-profile">
                <button className="page-title" onClick={showMenu}>{t('editProfile')}</button>

                <div className="page-profile__left">
                    <div className="profile-welcome">
                        <div className="profile-welcome__top-wrapper">
                            <div className="profile-welcome__img"><img src="/assets/images/profile-avatar.svg" alt="Profile avatar" /></div>
                        </div>

                        <span className="profile-welcome__name">
                            {t('welcome')}<br />
                            <strong>{currentUserTmp.first_name + ' ' + currentUserTmp.last_name}</strong>
                        </span>
                    </div>
                </div>

                <div className="page-profile__right">
                    <form className="page-profile__registration-form" onSubmit={saveProfile}>
                        <fieldset disabled={formIsDisabled}>
                            <div className="input-wrapper">
                                <input name="first_name" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('first_name') }} value={currentUserTmp.first_name} onChange={updateUser} className={'input' + (activeInputName === 'first_name' ? ' focus' : '')} placeholder={t('firstName') + ' *'} />
                            </div>

                            <div className="input-wrapper">
                                <input name="last_name" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('last_name') }} value={currentUserTmp.last_name} onChange={updateUser} className={'input' + (activeInputName === 'last_name' ? ' focus' : '')} placeholder={t('lastName') + ' *'} />
                            </div>

                            <div className="input-wrapper">
                                <input name="email" type="email" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('email') }} value={currentUserTmp.email} onChange={updateUser} className={'input' + (activeInputName === 'email' ? ' focus' : '')} placeholder={t('email')} />
                            </div>

                            <div className="input-wrapper">
                                <input name="company" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('company') }} value={currentUserTmp.company} onChange={updateUser} className={'input' + (activeInputName === 'company' ? ' focus' : '')} placeholder={t('company') + ' *'} />
                            </div>

                            <div className="input-wrapper">
                                <div className="lang-wrapper">
                                    <span>{t('preferredLanguage')}</span>

                                    <input checked={currentUserTmp.language === 'nl'} onChange={updateUser} id="pref-lang-nl" name="language" value="nl" type="radio" />
                                    <label htmlFor="pref-lang-nl"><img src="/assets/images/nl.png" alt="Language NL" /></label>

                                    <input checked={currentUserTmp.language === 'de'} onChange={updateUser} id="pref-lang-de" name="language" value="de" type="radio" />
                                    <label htmlFor="pref-lang-de"><img src="/assets/images/de.png" alt="Language DE" /></label>

                                    <input checked={currentUserTmp.language === 'en'} onChange={updateUser} id="pref-lang-en" name="language" value="en" type="radio" />
                                    <label htmlFor="pref-lang-en"><img src="/assets/images/en.png" alt="Language EN" /></label>
                                </div>
                            </div>

                            <div className="input-wrapper">
                                <input name="licence_plate" type="text" onFocus={(e) => { focusInputEl(e.target); setActiveInputName('licence_plate') }} value={currentUserTmp.licence_plate} onChange={updateUser} className={'input' + (activeInputName === 'licence_plate' ? ' focus' : '')} placeholder={t('licencePlate')} />
                            </div>

                            <button type="submit" className="page-profile__continue-btn side-btn">{t('saveProfile')}</button>
                        </fieldset>
                    </form>
                </div>

                <div className={'keyboard-wrapper' + (activeInputEl ? ' show' : '')}>
                    <Keyboard keyboardRef={r => { keyboard.current = r }} onChange={onKeyboardChange} onKeyPress={onKeyboardKeyPress} layoutName={keyboardLayout} excludeFromLayout={excludeFromKeyboardLayout} />
                </div>
            </div>
            : null}
    </>);
}

export default PageEditProfile;
