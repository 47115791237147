import { useState, useEffect, useReducer, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function InactiveModal({ logout }) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const timeout1 = 30 * 60;
    const timeout2 = (5 * 60) + timeout1;

    const [state, dispatch] = useReducer(reducer, { inactive: 0 });

    const resume = () => {
        dispatch({ type: 'reset' });
        setShowModal(false);

        const event = new Event('gmb-hide-inactive-modal');
        window.dispatchEvent(event);
    };

    const goodbye = useCallback(() => {
        console.log('Goodbye 👋🏻');

        dispatch({ type: 'reset' });
        setShowModal(false);

        logout();
        navigate('/');
    }, [dispatch, logout, navigate]);

    // Increase the inactive time every second
    useEffect(() => {
        const timer = setInterval(() => dispatch({ type: 'increment' }), 1000);
        return () => clearTimeout(timer);
    }, [dispatch]);

    // Check if the time exceeds the timeout and handle accordingly
    useEffect(() => {
        // Do not run the screensaver when we are at home
        if (location.pathname === '/') return;

        // If we are on the instruction page, the timeout needs to be a bit longer
        const time1 = location.pathname.includes('/instruction/') ? timeout1 + 30 : timeout1;
        const time2 = location.pathname.includes('/instruction/') ? timeout2 + 30 : timeout2;

        // Handle accordingly
        if (!showModal && state.inactive >= time1) {
            setShowModal(true);

            const event = new Event('gmb-show-inactive-modal');
            window.dispatchEvent(event);
        }
        else if (showModal && state.inactive >= time2) goodbye();
    }, [state.inactive, showModal, timeout1, timeout2, goodbye, location.pathname]);

    useEffect(() => {
        window.addEventListener('click', clearTimers);
        window.addEventListener('mousemove', clearTimers);
        window.addEventListener('keydown', clearTimers);

        function clearTimers() {
            dispatch({ type: 'reset' });
        }

        return () => {
            window.removeEventListener('click', clearTimers);
            window.removeEventListener('mousemove', clearTimers);
            window.removeEventListener('keydown', clearTimers);
        }
    }, [dispatch]);

    function reducer(state, action) {
        switch (action.type) {
            case 'reset' : return { inactive: 0 };
            case 'increment' : return { inactive: state.inactive + 1 };
            default : throw new Error();
        }
    }

    return (<>
        { showModal ?
            <div className="inactive-modal">
                <div>
                    <span className="inactive-modal__title">{ t('inactiveModalTitle') }</span>
                    <div className="inactive-modal__desc default-styles" dangerouslySetInnerHTML={ { __html: t('inactiveModalDesc') } }></div>

                    <div className="inactive-modal__btns">
                        <button type="button" className="btn btn--solid-green" onClick={ resume }>{ t('inactiveModalStillHereBtn') }</button>
                        <button type="button" className="btn btn--solid-yellow" onClick={ goodbye }>{ t('inactiveModalStopBtn') }</button>
                    </div>
                </div>
            </div>
        : null }
    </>);
}

export default InactiveModal;